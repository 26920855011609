import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { RvStatement, RvSummary } from './types'
import { ResponseError, ResponseSuccess } from '../../types'
import { BUSINESS_CODE } from '../auth'

export const KEY_GET_RV_STATEMENT_QUERY = 'useGetRVStatementQuery'

export const KEY_GET_RV_SUMMARY_QUERY = 'useGetRVSummaryQuery'

export const useGetRVStatementQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<RvStatement>>, AxiosError<ResponseError>>(
    [KEY_GET_RV_STATEMENT_QUERY],
    () => requests.getRvStatement()
  )

export const useGetRVSummaryQuery = (businessCode?: BUSINESS_CODE) =>
  useQuery<AxiosResponse<ResponseSuccess<RvSummary>>, AxiosError<ResponseError>>(
    [KEY_GET_RV_SUMMARY_QUERY, businessCode],
    () => requests.getRvSummary(businessCode!),
    {
      enabled: !!businessCode
    }
  )
