import { FunctionComponent, useEffect } from 'react'

import { Box, Text, Heading, VStack, useBreakpointValue } from 'native-base'
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'

import { ProgressBarChartProps } from './types'

const AnimatedBox = Animated.createAnimatedComponent(Box)

export const ProgressBarChart: FunctionComponent<ProgressBarChartProps> = ({
  currentProgress = 0,
  lines,
  bgColor = 'primary.500',
  style,
  showPercentage,
  showZeroSubtitle
}) => {
  const calculatedCurrentValue = useSharedValue('0%')

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const config = {
    duration: 1200,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
    damping: 20,
    stiffness: 90
  }

  const currentStyles = useAnimatedStyle(() => ({
    width: withTiming(calculatedCurrentValue.value, config)
  }))

  const currentStylesShort = useAnimatedStyle(() => ({
    width: withTiming(`${currentProgress}%`, config)
  }))

  useEffect(() => {
    calculatedCurrentValue.value = `${currentProgress + 1}%` // +1 visualy only
  }, [currentProgress, calculatedCurrentValue])

  return (
    <VStack style={style} alignItems="flex-end" space={isDesktop ? 1 : 2}>
      <Box w="100%" h={6} bgColor="gray.100" position="relative" rounded="full" overflow="hidden">
        {(isNaN(currentProgress) ? 0 : currentProgress) === 0 ? (
          <>
            <Box
              style={{
                paddingLeft: 6,
                height: '100%',
                position: 'absolute',
                overflow: 'hidden',
                borderRadius: 20,
                zIndex: 1,
                top: 1,
                left: 9
              }}>
              <Text
                color={bgColor}
                fontFamily="OpenSans_700Bold"
                fontSize={14}
                style={{
                  textShadowColor: bgColor,
                  textShadowRadius: 0,
                  textShadowOffset: {
                    width: 2,
                    height: 2
                  }
                }}>
                0%
              </Text>
            </Box>
            <Box
              style={{
                paddingLeft: 6,
                height: '100%',
                position: 'absolute',
                overflow: 'hidden',
                borderRadius: 20,
                zIndex: 2,
                top: 1,
                left: 11
              }}>
              <Text
                color="white"
                fontFamily="OpenSans_700Bold"
                fontSize={14}
                style={{
                  textShadowColor: bgColor,
                  textShadowRadius: 0,
                  textShadowOffset: {
                    width: 2,
                    height: 2
                  }
                }}>
                0%
              </Text>
            </Box>
          </>
        ) : (
          <>
            <AnimatedBox
              bgColor={bgColor}
              style={[
                {
                  paddingTop: 5,
                  paddingLeft: 6,
                  height: '100%',
                  position: 'absolute',
                  overflow: 'hidden',
                  borderRadius: 20,
                  zIndex: 1,
                  top: 0,
                  left: 0
                },
                (isNaN(currentProgress) ? 0 : currentProgress) < 10
                  ? currentStylesShort
                  : currentStyles
              ]}
            />

            {showPercentage && (
              <Box
                style={{
                  paddingTop: 3,
                  paddingLeft: 6,
                  height: '100%',
                  position: 'absolute',
                  overflow: 'hidden',
                  borderRadius: 20,
                  zIndex: 2,
                  top: 0,
                  left: 0
                }}>
                <Text color="white" fontSize="14px" lineHeight={18} fontWeight={700}>
                  {currentProgress}%
                </Text>
              </Box>
            )}
          </>
        )}
        {lines?.length > 0 &&
          lines?.map((line) => (
            <>
              {line?.position && (
                <Box
                  key={`${line?.position}-${line?.title}-${line?.subtitle}`}
                  w={1}
                  h={6}
                  rounded="full"
                  bgColor={`${line?.bgColor ?? 'white'}`}
                  position="absolute"
                  zIndex={3}
                  left={`${line?.position}%`}
                />
              )}
            </>
          ))}
      </Box>
      {lines?.length > 0 &&
        lines.map((line) => (
          <>
            {line?.title && (
              <Box
                position="absolute"
                top={isDesktop ? '-28px' : '40px'}
                left={`${line?.position - 1}%`}>
                <Heading color={line?.textColor ?? 'gray.400'} fontSize="16px" fontWeight="700">
                  {line?.title}
                </Heading>
              </Box>
            )}
          </>
        ))}
      {lines?.length > 0 &&
        lines.map((line, index) => (
          <>
            {line?.subtitle && (
              <Box
                position="absolute"
                top="28px"
                left={index === 0 ? `${line?.position - 4}%` : `${line?.position}%`}>
                <Heading color={line?.textColor ?? 'gray.400'} fontSize="16px" fontWeight="700">
                  {line?.subtitle}
                </Heading>
              </Box>
            )}
          </>
        ))}

      {showZeroSubtitle && (
        <Box position="absolute" top="28px" left={1}>
          <Heading color="gray.200" fontSize="16px" fontWeight="700">
            0
          </Heading>
        </Box>
      )}
    </VStack>
  )
}
