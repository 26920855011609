import { Platform } from 'react-native'

export const toFixedNoRounding = (num: number = 0, fixed: number): string => {
  // verify is a number
  num = parseFloat(String(num))

  if (isNaN(num)) {
    return '0.00' // return '0.00' if num is not a valid number
  }

  if (fixed !== undefined) {
    return num.toFixed(fixed)
  } else {
    return num.toFixed(2)
  }
}

export const currencyFormat = (value: string | number) => {
  if (Platform.OS === 'web') {
    return Number(value)
      .toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      })
      .replace('R$', '')
  } else {
    let valueFormatted = String(value)

    valueFormatted = valueFormatted?.replace(/(\d)(\d{2})$/, '$1,$2').replace('.', ',')

    valueFormatted = valueFormatted?.replace(/(?=(\d{3})+(\D))\B/g, '.')

    value = valueFormatted

    return value
  }
}

export const currencyFormatShortCut = (value: string) => {
  if (!value) return '0'

  const formatValue = String(value)?.replace('.00', '') ?? ''

  if (formatValue.length <= 6) return `${formatValue?.slice(0, 3)}Mil`
  else if (formatValue.length >= 7) return `${formatValue?.slice(0, 2).split('').join('.')}M`

  return value
}

export const currencyFormatChart = (
  value: string = '0',
  needsLabel: boolean,
  shortcut?: boolean,
  toFixed?: number
) => {
  const valueFormatted = String(parseFloat(toFixedNoRounding(Number(value), 2)))

  const precisionValue = valueFormatted?.includes('.')
    ? valueFormatted?.split('.')[1]
    : valueFormatted

  const integerValue = valueFormatted?.includes('.')
    ? valueFormatted?.split('.')[0]
    : valueFormatted

  const integerValueLength = integerValue.length

  if (shortcut) {
    if (Number(valueFormatted) < 10 && Number(precisionValue) > 0)
      return `${toFixedNoRounding(Number(valueFormatted), 2)}`
    else if (integerValueLength < 3) return `${toFixedNoRounding(Number(valueFormatted), 0)}`
    else if (integerValueLength < 6)
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000, toFixed ?? 0)}Mil`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000, toFixed ?? 0)}`
    else if (integerValueLength < 9)
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000000, 2)}M`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000000, 2)}`
    else if (integerValueLength < 12)
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000000000, 2)}B`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000000000, 2)}`
    else
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000000000000, 2)}T`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000000000000, 2)}`
  } else {
    if (Number(valueFormatted) < 10 && Number(precisionValue) > 0)
      return `${toFixedNoRounding(Number(valueFormatted), toFixed ?? 2)}`
    else if (Number(valueFormatted) === 0) return `${toFixedNoRounding(Number(valueFormatted), 0)}`
    else if (integerValueLength < 4)
      return `${toFixedNoRounding(Number(valueFormatted), toFixed ?? 0)}`
    else if (integerValueLength < 7)
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000, toFixed ?? 0)}Mil`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000, toFixed ?? 0)}`
    else if (integerValueLength < 10)
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000000, 2)}M`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000000, 2)}`
    else if (integerValueLength < 13)
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000000000, 2)}B`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000000000, 2)}`
    else
      return needsLabel
        ? `${toFixedNoRounding(Number(valueFormatted) / 1000000000000, 2)}T`
        : `${toFixedNoRounding(Number(valueFormatted) / 1000000000000, 2)}`
  }
}
