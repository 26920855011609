import { FunctionComponent } from 'react'

import { Cards } from 'atoms'
import { Box, FlatList, HStack, Pressable, useBreakpointValue } from 'native-base'
import { TouchableOpacity, useWindowDimensions } from 'react-native'
import { HomeCharge } from 'src/screens/HomeScreen/components/Charge'
import { HomeConcentration } from 'src/screens/HomeScreen/components/Concentration'
import { HomeFunnel } from 'src/screens/HomeScreen/components/Funnel'
import { HomeProposalFunnel } from 'src/screens/HomeScreen/components/ProposalFunnel'
import { HomeChartsTypes, HomeSelectWalletTypes } from 'src/screens/HomeScreen/constants'

type TSummaryList = {
  handleGoToProductionChart(): void
  isGoodsAndServices: boolean
  data: any[]
  type?: HomeSelectWalletTypes
  goToFullChart?: (type: HomeChartsTypes) => void
}

export const SummaryList: FunctionComponent<TSummaryList> = ({
  data = [],
  handleGoToProductionChart,
  isGoodsAndServices,
  type,
  goToFullChart
}) => {
  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const isFunnelTab = type === HomeSelectWalletTypes.FUNNEL

  const isExcellenceProgramTab = type === HomeSelectWalletTypes.EXCELLENCE_PROGRAM

  const widthCard = isExcellenceProgramTab
    ? Math.floor(SCREEN_WIDTH - 32)
    : Math.floor((SCREEN_WIDTH - 48) / 2)

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const DESKTOP_CARD_HEIGHT: { [key in HomeSelectWalletTypes | 'default']: number } = {
    [HomeSelectWalletTypes.FUNNEL]: 216,
    [HomeSelectWalletTypes.RESULT]: 244,
    [HomeSelectWalletTypes.CONCENTRATION]: 294,
    [HomeSelectWalletTypes.COMMISSION]: 294,
    [HomeSelectWalletTypes.CHARGE]: 294,
    [HomeSelectWalletTypes.EXCELLENCE_PROGRAM]: 298,
    default: 294
  }

  const cardHeight = DESKTOP_CARD_HEIGHT[type ?? 'default'] ?? DESKTOP_CARD_HEIGHT.default

  const list = data.filter((item) => item?.visible && !item?.isClassificationCard)

  const elementDesktopCard = (item: any, index: number, customHeight?: number) => {
    const isMiddleItem = (index + 1) % 3 === 2

    return (
      <Pressable
        w={SCREEN_WIDTH < 1400 ? '320px' : '354px'}
        h={customHeight ? customHeight : cardHeight}
        bg="white"
        onPress={() =>
          item.handleGoTo
            ? item.handleGoTo()
            : item.subtitle?.includes('Produção')
            ? handleGoToProductionChart
              ? handleGoToProductionChart()
              : {}
            : {}
        }
        _hover={
          item.subtitle?.includes('Produção')
            ? {
                style: {
                  shadowOffset: { width: 0, height: 3 },
                  shadowRadius: 16,
                  shadowColor: 'rgba(0, 0, 0, 0.08)',
                  transform: [
                    {
                      translateY: -2
                    }
                  ],
                  // @ts-ignore
                  transition: 'all 0.3s ease'
                }
              }
            : {}
        }
        mx={isMiddleItem ? '30px' : 0}
        borderRadius="20px"
        mb={isFunnelTab ? '0px' : '30px'}>
        {isExcellenceProgramTab ? (
          <Cards.ExcellenceProgramSummary
            {...item}
            desktopCardHeight={customHeight ? customHeight : cardHeight}
          />
        ) : (
          <Cards.Summary {...item} desktopCardHeight={customHeight ? customHeight : cardHeight} />
        )}
      </Pressable>
    )
  }

  const elementChart = () => {
    switch (type) {
      case HomeSelectWalletTypes.FUNNEL:
        return (
          <HomeFunnel
            handleGoToFullChartScreen={goToFullChart ? goToFullChart : () => {}}
            isGoodsAndServices={isGoodsAndServices}
          />
        )

      case HomeSelectWalletTypes.CONCENTRATION:
        return (
          <HomeConcentration
            handleGoToFullChartScreen={goToFullChart ? goToFullChart : () => {}}
            isGoodsAndServices={isGoodsAndServices}
          />
        )

      case HomeSelectWalletTypes.CHARGE:
        return (
          <HomeCharge
            handleGoToFullChartScreen={goToFullChart ? goToFullChart : () => {}}
            isGoodsAndServices={isGoodsAndServices}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <>
      {isExcellenceProgramTab && (
        <Box w={`${widthCard}px`}>
          <Cards.ExcellenceProgramScore {...data.filter((item) => item.isClassificationCard)[0]} />
        </Box>
      )}
      {!isDesktop && isFunnelTab && <HomeProposalFunnel />}
      <HStack flexWrap="wrap">
        {!isDesktop ? (
          <>
            {list.map((item, index) => {
              if (item?.visible) {
                return (
                  <TouchableOpacity
                    onPress={() =>
                      item.handleGoTo
                        ? item.handleGoTo()
                        : item.subtitle?.includes('Produção')
                        ? handleGoToProductionChart
                          ? handleGoToProductionChart()
                          : {}
                        : {}
                    }
                    key={Math.random()}
                    style={{
                      paddingLeft: index % 2 !== 0 && !isExcellenceProgramTab ? 16 : 0,
                      width: widthCard
                    }}>
                    <Box w={`${widthCard}px`}>
                      {isExcellenceProgramTab ? (
                        <Cards.ExcellenceProgramSummary {...item} />
                      ) : (
                        <Cards.Summary {...item} />
                      )}
                    </Box>
                  </TouchableOpacity>
                )
              }
            })}
          </>
        ) : (
          <>
            {isFunnelTab && (
              <HStack w="1122px">
                <HomeProposalFunnel />
                {list.length > 0 && elementDesktopCard(list[0], 0, 455)}
              </HStack>
            )}
            <FlatList
              data={isFunnelTab ? list.slice(1) : list}
              renderItem={({ item, index }) => elementDesktopCard(item, index)}
              keyExtractor={(_, key) => key.toString()}
              numColumns={3}
            />
          </>
        )}
      </HStack>
      {elementChart()}
    </>
  )
}
