import { FunctionComponent } from 'react'

import Icons from 'atoms/Icons'
import { LinearGradient } from 'expo-linear-gradient'
import { Text, HStack, useBreakpointValue, Divider } from 'native-base'
import { theme } from 'src/theme'

type ExcellenceProgramScoreCardProps = {
  title: string
  stars: number
  description: {
    title: string
    value: number
  }[]
}

export const ExcellenceProgramScoreCard: FunctionComponent<ExcellenceProgramScoreCardProps> = ({
  description,
  title,
  stars
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <LinearGradient
      colors={[
        theme.colors['header-gradient'][50],
        theme.colors['header-gradient'][100],
        theme.colors['header-gradient'][200]
      ]}
      style={{
        borderRadius: 20,
        marginBottom: isMobile ? 24 : 32,
        width: isMobile ? undefined : '1122px'
      }}>
      <HStack
        w={{ base: 'auto', lg: '1122px' }}
        maxW="1122px"
        key={Math.random()}
        py={6}
        px={6}
        flexDir={{
          base: 'column',
          lg: 'row'
        }}
        justifyContent="space-between"
        alignItems="center"
        minH={{
          base: '215px',
          lg: '80px'
        }}>
        <HStack
          alignItems="center"
          flexDir={{ base: 'column', lg: 'row' }}
          mb={{ base: '32px', lg: 0 }}>
          <Text
            fontSize="14px"
            lineHeight="18px"
            color="white"
            fontWeight={700}
            numberOfLines={1}
            mb={{ base: 4, lg: 0 }}
            mr={{ base: 0, lg: 2 }}>
            {title}
          </Text>
          <HStack space={2}>
            <Icons.Star filled size={10} color={stars >= 1 ? '#FBAD0A' : 'gray.600'} />
            <Icons.Star filled size={10} color={stars >= 2 ? '#FBAD0A' : 'gray.600'} />
            <Icons.Star filled size={10} color={stars >= 3 ? '#FBAD0A' : 'gray.600'} />
            <Icons.Star filled size={10} color={stars >= 4 ? '#FBAD0A' : 'gray.600'} />
            <Icons.Star filled size={10} color={stars === 5 ? '#FBAD0A' : 'gray.600'} />
          </HStack>
        </HStack>

        <HStack
          justifyContent="space-between"
          alignItems="center"
          space={{ base: 2, lg: 6 }}
          flexDir={{ base: 'column', lg: 'row' }}>
          {!isMobile && <Divider orientation="vertical" borderColor="gray.50" />}
          {description.map((item, index) => (
            <HStack
              alignItems="center"
              space={1}
              key={`${index}-${item?.value}`}
              mb={{ base: 2, lg: 0 }}>
              <Text
                fontSize="12px"
                lineHeight="16px"
                color="white"
                fontFamily="OpenSans_400Regular"
                numberOfLines={1}>
                {item.title}
              </Text>
              <Text
                fontSize="14px"
                color="white"
                fontFamily="OpenSans_700Bold"
                lineHeight="16px"
                numberOfLines={1}>
                {item.value}
              </Text>
            </HStack>
          ))}
        </HStack>
      </HStack>
    </LinearGradient>
  )
}
