import { FunctionComponent } from 'react'

import { ProgressBarChart } from 'molecules'
import {
  VStack,
  Heading,
  Text,
  Box,
  Divider,
  HStack,
  useBreakpointValue,
  Center
} from 'native-base'

type ExcellenceProgramSummaryCardProps = {
  desktopCardHeight?: number
  title: string
  subtitle: string
  description: {
    title: string
    value: string
  }[]
  tips?: {
    title: string
    color: string
  }[]
  progressBar?: {
    lines: {
      position: number
      bgColor?: string
      title?: string
      subtitle?: string
    }[]
    currentProgress: number
    bgColor?: string
  }
}

export const ExcellenceProgramSummaryCard: FunctionComponent<ExcellenceProgramSummaryCardProps> = ({
  desktopCardHeight,
  title,
  subtitle,
  description,
  tips,
  progressBar
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <VStack
      key={Math.random()}
      mb={{
        base: 4,
        lg: 0
      }}
      py={{
        base: 4,
        lg: 6
      }}
      px={{ base: 4, lg: 6 }}
      bg="white"
      borderRadius={20}
      minH={{
        base: 238,
        lg: desktopCardHeight
      }}>
      <VStack flex={1}>
        <Heading
          fontSize={{ base: 14, lg: 18 }}
          lineHeight={{ base: '18px', lg: '24px' }}
          fontWeight="bold"
          color="gray.700"
          numberOfLines={1}>
          {title}
        </Heading>

        {subtitle && (
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            fontFamily={{ base: 'OpenSans_400Regular' }}
            color="gray.700"
            lineHeight={{ base: '16px', lg: '24px' }}
            fontWeight="normal"
            mt={4}
            numberOfLines={1}>
            {subtitle}
          </Text>
        )}

        <VStack mt={2}>
          {description?.length > 0 &&
            description?.map((item) => (
              <Box
                key={`ExcellenceProgramSummaryCard-${item.value}-${Math.random()}`}
                mb={3}
                minH={{ base: '16px', lg: 0 }}>
                <HStack alignItems="center" space={1}>
                  <Text
                    fontSize={{ base: '12px', lg: '16px' }}
                    fontFamily={{ base: 'OpenSans_400Regular' }}
                    lineHeight={{ base: '16px', lg: '24px' }}
                    color="gray.700"
                    fontWeight="normal"
                    numberOfLines={1}>
                    {item.title}
                  </Text>
                  <Text
                    fontSize={{ base: '14px', lg: '16px' }}
                    color="gray.700"
                    lineHeight={{ base: '16px', lg: '24px' }}
                    letterSpacing={{ lg: '0.5px' }}
                    fontWeight="bold"
                    numberOfLines={1}>
                    {item.value}
                  </Text>
                </HStack>
              </Box>
            ))}
        </VStack>
        <ProgressBarChart
          currentProgress={progressBar?.currentProgress ?? 0}
          lines={progressBar?.lines ?? []}
          bgColor={progressBar?.bgColor}
          showZeroSubtitle
        />
      </VStack>
      <VStack w="full">
        <Divider bg="gray.50" my={4} />
        <HStack flexWrap="wrap" w="full">
          {tips &&
            tips.length > 0 &&
            tips?.map((tip) => (
              <HStack alignItems="center" mb={isMobile ? 2 : 0} mr={3} key={tip?.title}>
                <Center bg={tip.color} borderRadius="full" size="10px" mr="2px" />
                <Text color="gray.400" fontSize="10px" fontWeight={600}>
                  {tip?.title}
                </Text>
              </HStack>
            ))}
        </HStack>
      </VStack>
    </VStack>
  )
}
