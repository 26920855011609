import { useMemo, useRef, useState } from 'react'

import { HeadPage, Icons, LoadingTurbo } from 'atoms'
import { Dropdown, DropdownRefProps } from 'atoms/web/Dropdown'
import { format } from 'date-fns'
import { TBodyItem } from 'integration/resources/analytics'
import {
  Box,
  Button,
  FormControl,
  HStack,
  Menu,
  ScrollView,
  Skeleton,
  Text,
  VStack
} from 'native-base'
import { InputSearch } from 'organisms'
import Layouts from 'organisms/Layouts'
import Table from 'organisms/Table'
import { CSVLink } from 'react-csv'
import { LogEvent } from 'src/utils/logEvents'

import { AnalyticsScreenComponent, MonthOptions } from './AnalyticsScreen.types'
import { useAnalyticsScreen } from './useAnalyticsScreen'

const AnalyticsScreen: AnalyticsScreenComponent = ({ navigation, route }) => {
  const {
    selectedReportId,
    handleReportChange,
    columns,
    analyticsList,
    availableReports,
    isLoadingAvailableReports,
    isLoadingAnalytics,
    isRefetchingAnalytics,
    currentYearMonth,
    handleSelectYearMonth,
    handleSearchStdCode,
    searchStdCode,
    monthsList,
    screenHeight,
    isAdmin,
    totalResults
  } = useAnalyticsScreen({
    navigation,
    route
  })

  const csvHeaders = analyticsList?.header.map((col) => ({
    label: col.slug,
    key: String(col.name)
  }))

  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>(null)

  const handleDownloadClick = () => {
    if (csvLinkRef.current?.link) {
      LogEvent(`analitico_download_csv`, {
        item_id: selectedReportId
      })

      csvLinkRef.current.link.click()
    }
  }

  const [filteredTableData, setFilteredTableData] = useState<TBodyItem[]>([])

  const menuFilterRef = useRef<DropdownRefProps>(null)

  const currentDate = format(new Date(), 'yyyyMM')

  const serializedFilteredData = useMemo(() => {
    return filteredTableData.map((item) => {
      const formattedItem = { ...item }

      Object.keys(formattedItem).forEach((key) => {
        if (
          !isNaN(Number(formattedItem[key])) &&
          (key.startsWith('vl_') || key.startsWith('value'))
        ) {
          formattedItem[key] = Number(formattedItem[key]).toFixed(2).replace('.', ',')
        }
      })

      return formattedItem
    })
  }, [filteredTableData])

  return (
    <Layouts.InternalDesktop hiddenSearch>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: screenHeight > 900 ? 27 : 18,
          paddingHorizontal: 30
        }}>
        {screenHeight > 900 && <HeadPage title="Analítico" pages={[]} />}

        <VStack>
          <VStack mt={screenHeight > 900 ? 8 : 0} mb={screenHeight > 900 ? 12 : 4} space={8}>
            <HStack space={2}>
              {availableReports?.map((report) => {
                return (
                  <HStack key={report.id}>
                    <FormControl>
                      <Button
                        isDisabled={isLoadingAnalytics || isRefetchingAnalytics}
                        _disabled={{ opacity: 0.5 }}
                        colorScheme={selectedReportId !== report.id ? 'white' : 'primary-light'}
                        px={5}
                        h={screenHeight > 900 ? 10 : 8}
                        maxW="md"
                        justifyContent="center"
                        borderRadius="full"
                        onPress={() => {
                          handleReportChange(report.id)
                        }}>
                        <HStack alignItems="center">
                          <Text
                            fontSize="16px"
                            lineHeight="22px"
                            color={
                              isLoadingAnalytics || isRefetchingAnalytics
                                ? 'white'
                                : selectedReportId !== report.id
                                ? 'black'
                                : 'primary.800'
                            }
                            fontWeight="normal">
                            {report.name}
                          </Text>
                        </HStack>
                      </Button>
                    </FormControl>
                  </HStack>
                )
              })}
            </HStack>
          </VStack>
          <VStack mb={screenHeight > 900 ? 12 : 4} space={8}>
            <HStack alignItems="center" justifyContent="space-between" w="full">
              <HStack>
                {isAdmin && (
                  <InputSearch
                    h={screenHeight > 900 ? 10 : 8}
                    keyboardType="numeric"
                    isDisabled={
                      isLoadingAnalytics || isRefetchingAnalytics || isLoadingAvailableReports
                    }
                    placeholder="Nº da Matrícula *"
                    applyFilter={handleSearchStdCode}
                  />
                )}
              </HStack>

              <HStack>
                <Dropdown
                  w={140}
                  buttonH={screenHeight > 900 ? 10 : 8}
                  buttonProps={{
                    isDisabled: isLoadingAnalytics || isRefetchingAnalytics,
                    _disabled: { opacity: 0.5 }
                  }}
                  key="filter-by-date"
                  ref={menuFilterRef}
                  label={
                    (currentYearMonth &&
                      monthsList?.find((month) => month.value === currentYearMonth)?.label) ??
                    'Filtrar por data'
                  }
                  active={currentYearMonth !== currentDate}
                  renderItems={
                    <ScrollView w={140} style={{ maxHeight: screenHeight > 900 ? 420 : 320 }}>
                      {monthsList?.map((month: MonthOptions) => (
                        <Menu.Item
                          width="full"
                          alignItems="center"
                          key={month.value}
                          onPress={() => {
                            handleSelectYearMonth(month.value)
                          }}
                          bgColor={currentYearMonth === month.value ? 'gray.50' : 'white'}>
                          <HStack py={2}>
                            <Text fontSize="14px" lineHeight="24px" color="gray.500">
                              {month.label}
                            </Text>
                          </HStack>
                        </Menu.Item>
                      ))}
                    </ScrollView>
                  }
                />

                <>
                  <Button
                    isDisabled={
                      isLoadingAnalytics || !analyticsList?.body.length || isRefetchingAnalytics
                    }
                    _disabled={{ opacity: 0.7 }}
                    colorScheme="gray"
                    startIcon={<Icons.Downloading />}
                    px={5}
                    ml={6}
                    h={screenHeight > 900 ? 10 : 8}
                    maxW="md"
                    justifyContent="center"
                    borderRadius="full"
                    onPress={handleDownloadClick}>
                    <Text fontSize="16px" lineHeight="22px" color="white" fontWeight="normal">
                      Baixar CSV
                    </Text>
                  </Button>

                  <CSVLink
                    separator=";"
                    data={serializedFilteredData}
                    headers={csvHeaders}
                    filename="analytics-data.csv"
                    ref={csvLinkRef}
                    style={{ display: 'none' }}
                  />
                </>
              </HStack>
            </HStack>
          </VStack>

          {isLoadingAvailableReports ? (
            <LoadingTurbo />
          ) : (
            <>
              {isAdmin && !searchStdCode ? (
                <Box
                  p={6}
                  bg="white"
                  w="full"
                  height={screenHeight > 900 ? 432 : 272}
                  borderRadius="20px"
                  alignItems="center"
                  justifyContent="center">
                  <Text fontSize="16px" lineHeight="22px" color="gray.500" fontWeight="normal">
                    Digite o Nº da Matrícula para exibir os dados
                  </Text>
                </Box>
              ) : (
                <Table
                  data={analyticsList?.body ?? []}
                  columns={columns}
                  setFilteredData={setFilteredTableData}
                  isLoading={isLoadingAnalytics || isRefetchingAnalytics}
                />
              )}
            </>
          )}

          <HStack mt={4} alignItems="center" justifyContent="end" w="full">
            {isLoadingAnalytics || isRefetchingAnalytics ? (
              <Skeleton h="full" w="130px" />
            ) : (
              <Text fontSize="16px" lineHeight="22px" color="gray.500" fontWeight="normal">
                {totalResults ? `${totalResults} resultados` : ''}
              </Text>
            )}
          </HStack>
        </VStack>
      </ScrollView>
    </Layouts.InternalDesktop>
  )
}

export default AnalyticsScreen
