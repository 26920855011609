import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import {
  ChargeDataSerialized,
  CommissionDataSerialized,
  ConcentrationDataSerialized,
  ExcellenceProgramData,
  FunnelDataSerialized,
  GetWalletResumeParams,
  ResultDataSerialized
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_CHARGE_QUERY = 'getCharge'

export const KEY_COMMISSION_QUERY = 'getCommission'

export const KEY_CONCENTRATION_QUERY = 'getConcentration'

export const KEY_FUNNEL_QUERY = 'getFunnel'

export const KEY_RESULT_QUERY = 'getResult'

export const KEY_EXCELLENCE_PROGRAM_QUERY = 'getExcellenceProgram'

export const useGetChargeQuery = (params: GetWalletResumeParams) =>
  useQuery<AxiosResponse<ResponseSuccess<ChargeDataSerialized>>, AxiosError<ResponseError>>(
    [KEY_CHARGE_QUERY],
    () => requests.getCharge(params),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000) // 15 mins
    }
  )

export const useGetCommissionQuery = (params: GetWalletResumeParams) =>
  useQuery<AxiosResponse<ResponseSuccess<CommissionDataSerialized>>, AxiosError<ResponseError>>(
    [KEY_COMMISSION_QUERY],
    () => requests.getCommission(params),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000) // 15 mins
    }
  )

export const useGetConcentrationQuery = (params: GetWalletResumeParams) =>
  useQuery<AxiosResponse<ResponseSuccess<ConcentrationDataSerialized>>, AxiosError<ResponseError>>(
    [KEY_CONCENTRATION_QUERY],
    () => requests.getConcentration(params),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000) // 15 mins
    }
  )

export const useGetFunnelQuery = (params: GetWalletResumeParams) =>
  useQuery<AxiosResponse<ResponseSuccess<FunnelDataSerialized>>, AxiosError<ResponseError>>(
    [KEY_FUNNEL_QUERY],
    () => requests.getFunnel(params),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000) // 15 mins
    }
  )

export const useGetResultQuery = (params: GetWalletResumeParams) => {
  return useQuery<AxiosResponse<ResponseSuccess<ResultDataSerialized>>, AxiosError<ResponseError>>(
    [KEY_RESULT_QUERY],
    () => requests.getResult(params),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000) // 15 mins
    }
  )
}

export const useGetExcellenceProgramQuery = (params: GetWalletResumeParams) => {
  return useQuery<AxiosResponse<ResponseSuccess<ExcellenceProgramData>>, AxiosError<ResponseError>>(
    ['KEY_EXCELLENCE_PROGRAM_QUERY', params],
    () => requests.getExcellenceProgram(params),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000) // 15 mins
    }
  )
}
