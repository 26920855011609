import { FunctionComponent } from 'react'

import Svg, { SvgProps, Path } from 'react-native-svg'

export const FormationIcon: FunctionComponent<SvgProps> = ({ ...props }) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      stroke="#A8A8A8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M23 8 12 4 1 8l11 4 11-4Zm0 0v6"
    />
    <Path
      stroke="#A8A8A8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 10v5.786c0 .852.632 1.67 1.757 2.273C8.883 18.66 10.41 19 12 19c1.591 0 3.117-.339 4.243-.941 1.125-.603 1.757-1.42 1.757-2.273V10"
    />
  </Svg>
)
