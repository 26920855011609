import {
  HomeContentScreen,
  EditProfileScreen,
  HomeScreen,
  HomeFilterScreen,
  OptionsProfileScreen,
  ProfileScreen,
  ContactScreen,
  ContactDetailsScreen,
  ContactBirthdayScreen,
  ContactEditBirthdayScreen,
  ContactSearchScreen,
  ContactFilterScreen,
  TabSearchScreen,
  TabFilterScreen,
  SecurityProfileScreen,
  LockscreenScreen,
  CreateAccountCreatePasswordScreen,
  PreferencesProfileScreen,
  DeviceManagementScreen,
  IntegrationProfileScreen,
  SimulatorScreen,
  OpportunityScreen,
  NotificationDetailScreen,
  PageNotFoundScreen,
  PageNoConnectionScreen,
  PageErrorOccurredScreen,
  ContactEditScreen,
  ContactCreateScreen,
  ContentScreen,
  ContentByCategoryScreen,
  TabScreen,
  NoteDetailsScreen,
  NoteEditDetailsScreen,
  TabContactScreen,
  TabRelatedScreen,
  TabDetailsScreen,
  MoreModulesScreen,
  TabEditScreen,
  NoteCreateDetailsScreen,
  OrganizationActivityScreen,
  TabLocationScreen,
  OrganizationOpportunityScreen,
  CreateActivityScreen,
  EditActivityScreen,
  HomeChartScreen,
  ProductionChartScreen,
  ProductionScreen,
  RemoveAccountConfirmScreen,
  AboutScreen,
  TermsScreen,
  ResetPasswordSendEmailScreen,
  ResetPasswordEmailSentSuccessfullyScreen,
  ResetPasswordReSentEmailScreen,
  ChartScreen,
  ChatScreen,
  ManageNotificationsScreen,
  ContractListScreen,
  NotificationScreen,
  SurveyScreen,
  SurveyDetailScreen,
  SSOAuthScreen
} from 'src/screens'
import AnalyticsScreen from 'src/screens/AnalyticsScreen'
import OrganizationScreenActivityDesktop from 'src/screens/OrganizationScreen/OrganizationScreenActivity.desktop'
import OrganizationScreenOpportunityDesktop from 'src/screens/OrganizationScreen/OrganizationScreenOpportunity.desktop'
import {
  NoteCreateDetailsContactScreen,
  NoteCreateDetailsTabScreen
} from 'src/screens/Wallet/Notes/NoteCreateDetailsScreen/NoteCreateDetailsScreen'
import {
  NoteEditDetailsContactScreen,
  NoteEditDetailsTabScreen
} from 'src/screens/Wallet/Notes/NoteEditDetailsScreen/NoteEditDetailsScreen'
import WalletContactScreenDesktop from 'src/screens/Wallet/WalletScreenContact.desktop'
import WalletTabScreenDesktop from 'src/screens/Wallet/WalletScreenTab.desktop'

import { RootStackScreenComponent } from '.'
import { TabNavigator } from './TabNavigator'
import { RootStackParamList } from './types'

export const loggedScreens: Partial<{
  [K in keyof RootStackParamList]: RootStackScreenComponent<K>
}> = {
  Tab: TabNavigator,
  Home: HomeScreen,
  HomeFilter: HomeFilterScreen,
  MoreModules: MoreModulesScreen,
  Profile: ProfileScreen,
  OptionsProfile: OptionsProfileScreen,
  SecurityProfile: SecurityProfileScreen,
  PreferencesProfile: PreferencesProfileScreen,
  Lockscreen: LockscreenScreen,
  CreateAccountCreatePassword: CreateAccountCreatePasswordScreen,
  DeviceManagement: DeviceManagementScreen,
  IntegrationProfile: IntegrationProfileScreen,
  EditProfile: EditProfileScreen,
  HomeContent: HomeContentScreen,
  Content: ContentScreen,
  ContentByCategory: ContentByCategoryScreen,
  Analytics: AnalyticsScreen,
  Notification: NotificationScreen,
  Simulator: SimulatorScreen,
  Opportunity: OpportunityScreen,
  NotificationDetail: NotificationDetailScreen,
  ErrorPageNotFound: PageNotFoundScreen,
  ErrorPageNoConnection: PageNoConnectionScreen,
  ErrorPageErrorOccurred: PageErrorOccurredScreen,
  WalletContact: ContactScreen,
  TabSearch: TabSearchScreen,
  TabFilter: TabFilterScreen,
  WalletTab: TabScreen,
  TabDetails: TabDetailsScreen,
  TabEdit: TabEditScreen,
  TabLocation: TabLocationScreen,
  ContactSearch: ContactSearchScreen,
  ContactFilter: ContactFilterScreen,
  ContactDetails: ContactDetailsScreen,
  ContactBirthday: ContactBirthdayScreen,
  ContactEdit: ContactEditScreen,
  ContactCreate: ContactCreateScreen,
  ContactEditBirthday: ContactEditBirthdayScreen,
  NoteDetails: NoteDetailsScreen,
  CreateNoteDetails: NoteCreateDetailsScreen,
  ContactCreateNoteDetails: NoteCreateDetailsContactScreen,
  TabCreateNoteDetails: NoteCreateDetailsTabScreen,
  ContactEditNoteDetails: NoteEditDetailsContactScreen,
  TabEditNoteDetails: NoteEditDetailsTabScreen,
  EditNoteDetails: NoteEditDetailsScreen,
  TabContact: TabContactScreen,
  TabRelated: TabRelatedScreen,
  OrganizationActivity: OrganizationActivityScreen,
  OrganizationActivityList: OrganizationScreenActivityDesktop,
  OrganizationOpportunityList: OrganizationScreenOpportunityDesktop,
  OrganizationOpportunity: OrganizationOpportunityScreen,
  OrganizationActivityCreate: CreateActivityScreen,
  OrganizationActivityEdit: EditActivityScreen,
  Chart: ChartScreen,
  HomeChart: HomeChartScreen,
  ProductionChart: ProductionChartScreen,
  Production: ProductionScreen,
  RemoveAccountConfirm: RemoveAccountConfirmScreen,
  ResetPasswordSendEmail: ResetPasswordSendEmailScreen,
  ResetPasswordEmailSentSuccessfully: ResetPasswordEmailSentSuccessfullyScreen,
  ResetPasswordReSentEmail: ResetPasswordReSentEmailScreen,
  About: AboutScreen,
  Terms: TermsScreen,
  Chat: ChatScreen,
  ManageNotifications: ManageNotificationsScreen,
  ContractList: ContractListScreen,
  WalletContactList: WalletContactScreenDesktop,
  WalletTabList: WalletTabScreenDesktop,
  Survey: SurveyScreen,
  SurveyDetail: SurveyDetailScreen,
  SSOAuth: SSOAuthScreen
}
