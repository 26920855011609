import * as serializers from './serializers'
import { RvStatement, RvSummary } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'
import { BUSINESS_CODE } from '../auth'

export const getRvStatement = async () =>
  await client.get<ResponseSuccess<RvStatement>>(`v1/statement`)

export const getRvSummary = async (businessCode: BUSINESS_CODE) =>
  serializers.getRvSummary(
    await client.get<ResponseSuccess<RvSummary>>(`v2/statement`),
    businessCode
  )
