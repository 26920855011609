import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { useLinkTo } from '@react-navigation/native'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import * as ScreenOrientation from 'expo-screen-orientation'
import {
  ContentSerialized,
  useGetKnowledgeBasesInfiniteQuery
} from 'integration/resources/knowledgeBase'
import {
  NOTIFICATION_TYPES,
  TNotification,
  useGetAllNotificationsInfinityQuery
} from 'integration/resources/notifications'
import { useGetOpportunitiesSummaryQuery } from 'integration/resources/opportunities'
import { useGetRVStatementQuery, useGetRVSummaryQuery } from 'integration/resources/rv'
import { TIndicatorResume, useGetIndicatorResumeQuery } from 'integration/resources/rvIndicator'
import { useGetProfileQuery } from 'integration/resources/user'
import {
  ChargeDataSerialized,
  CommissionDataSerialized,
  ConcentrationDataSerialized,
  ExcellenceProgramData,
  FunnelDataSerialized,
  GetWalletResumeParams,
  ResultDataSerialized,
  SwitchHookReturnType,
  useGetChargeQuery,
  useGetCommissionQuery,
  useGetConcentrationQuery,
  useGetExcellenceProgramQuery,
  useGetFunnelQuery,
  useGetResultQuery
} from 'integration/resources/walletResume'
import { useBreakpointValue } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useQueryClient } from 'react-query'
import { useAuthAtomValue } from 'src/store/auth'
import { formatDateStringToMask } from 'src/utils'
import { currencyFormatChart } from 'src/utils/currencyFormat'
import { LogEvent } from 'src/utils/logEvents'

import { HomeChartsTypes, HomeSelectWalletTypes } from './constants'
import { HomeFilterWalletFormFields } from './HomeFilterScreen/components/Form/types'
import { THandleGoToOpportunitiesListBySegment, UseHomeScreen } from './HomeScreen.types'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

const sanitizeFilters = (
  parameters: { refetch?: boolean } & Partial<HomeFilterWalletFormFields> = {}
): { params: HomeFilterWalletFormFields; size: number; refetch?: boolean } => {
  let size = 0

  const params: HomeFilterWalletFormFields = {
    segment: {},
    sub_segment: {},
    micro_region: undefined
  }

  if (parameters && typeof parameters === 'object') {
    if ('micro_region' in parameters && parameters.micro_region) {
      params.micro_region = parameters.micro_region

      size++
    }

    if ('segment' in parameters && parameters.segment?.code) {
      params.segment = parameters.segment

      size++
    }

    if ('sub_segment' in parameters && parameters.sub_segment?.code) {
      params.sub_segment = parameters.sub_segment

      size++
    }
  }

  return {
    params,
    size,
    refetch: parameters?.refetch
  }
}

const daysOfWeek = {
  dom: 'Dom',
  sab: 'Sáb',
  seg: 'Seg',
  ter: 'Ter',
  qua: 'Qua',
  qui: 'Qui',
  sex: 'Sex'
}

const setProgressValue = (data: TIndicatorResume) => ({
  potentials: data.rv_potentials ?? [],
  projected: {
    current: data.total_projection_point ? Number(data.total_projection_point) : 0,
    pointsAmount: data.total_projection_value ? Number(data.total_projection_value) : 0,
    currencyAmount: data.total_projection_value ? data.total_projection_value : 0
  },
  realized: {
    current: data.total_current_point ? Number(data.total_current_point) : 0,
    pointsAmount: data.total_current_value ? Number(data.total_current_value) : 0,
    currencyAmount: data.total_current_value ? data.total_current_value : 0
  },
  baseline: {
    current: data.total_baseline_point ? Number(data.total_baseline_point) : 0,
    pointsAmount: data.total_baseline_value ? Number(data.total_baseline_value) : 0,
    currencyAmount: data.total_baseline_value ? data.total_baseline_value : 0
  },
  trigger: {
    current: data.total_trigger_point ? Number(data.total_trigger_point) : 0,
    pointsAmount: data.total_trigger_value ? Number(data.total_trigger_value) : 0,
    currencyAmount: data.total_trigger_value ? data.total_trigger_value : 0
  },
  trigger_two: {
    current: data.total_trigger_point ? Number(data.total_trigger_point) : 0,
    pointsAmount: data.total_trigger_two_value ? Number(data.total_trigger_two_value) : 0,
    currencyAmount: data.total_trigger_two_value ? data.total_trigger_two_value : 0
  }
})

const getProgressBarColor = (
  currentValue: number,
  challengeOneCurrentValue: number,
  challengeTwoCurrentValue?: number
) => {
  if (currentValue >= challengeOneCurrentValue) {
    return 'primary.500'
  }

  if (challengeTwoCurrentValue && currentValue >= challengeTwoCurrentValue) {
    return 'primary.800'
  }

  return 'error.500'
}

const mergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetKnowledgeBasesInfiniteQuery>['data']
) =>
  data?.pages.reduce<ContentSerialized[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useHomeScreen: UseHomeScreen = ({ navigation, route }) => {
  const [refreshing, setRefreshing] = useState(false)

  const { params, size: filtersSize } = sanitizeFilters(route?.params)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const queryClient = useQueryClient()

  const authAtom = useAuthAtomValue()

  const profileBusinessCode = useMemo(
    () => authAtom?.user.business_code,
    [authAtom?.user.business_code]
  )

  const isVehicles = useMemo(
    () => authAtom?.user.business_code === 2,
    [authAtom?.user.business_code]
  )

  const isGoodsAndServices = useMemo(
    () => authAtom?.user.business_code === 3,
    [authAtom?.user.business_code]
  )

  const isLeves = useMemo(() => authAtom?.user.business_code === 4, [authAtom?.user.business_code])

  const isPesados = useMemo(
    () => authAtom?.user.business_code === 5,
    [authAtom?.user.business_code]
  )

  const { data: rvSummaryData, isLoading: isLoadingRvSummary } =
    useGetRVSummaryQuery(profileBusinessCode)

  const { data: rvStatement } = useGetRVStatementQuery()

  const consultantRV = rvStatement?.data?.data?.consultant

  // @TODO Remove when refactor mobile to receive dynamic data too.

  const regionalRV = rvStatement?.data?.data?.regional

  const [walletSelected, setWalletSelected] = useState<HomeSelectWalletTypes>(
    HomeSelectWalletTypes.RESULT
  )

  const isExcellenceProgramWallet = useMemo(
    () => walletSelected === HomeSelectWalletTypes.EXCELLENCE_PROGRAM,
    [walletSelected]
  )

  const [isLoadingWalletSelected, setIsLoadingWalletSelected] = useState(false)

  const handleResetForm = () => {
    const resetFields = {
      segment: undefined,
      sub_segment: undefined,
      micro_region: undefined
    }

    navigation.setParams(resetFields)
  }

  const onChangeSelectWallet = (wallet: HomeSelectWalletTypes) => {
    if (wallet === walletSelected) return

    setIsLoadingWalletSelected(true)

    setTimeout(
      () => {
        setWalletSelected(wallet ?? '')

        setIsLoadingWalletSelected(false)

        handleResetForm()
      },
      isMobile ? 1500 : 0
    )
  }

  const {
    data: OpportunitiesList,
    refetch: refetchOpportunities,
    isLoading: isLoadingOpportunities
  } = useGetOpportunitiesSummaryQuery()

  const opportunitiesList = !isVehicles
    ? OpportunitiesList?.data.data
    : OpportunitiesList?.data.data.filter(
        (opportunity) => opportunity.id !== 2 && opportunity.id !== 3
      )

  const switchHook = (type: HomeSelectWalletTypes) => {
    switch (type) {
      case HomeSelectWalletTypes.CHARGE:
        return useGetChargeQuery

      case HomeSelectWalletTypes.COMMISSION:
        return useGetCommissionQuery

      case HomeSelectWalletTypes.CONCENTRATION:
        return useGetConcentrationQuery

      case HomeSelectWalletTypes.FUNNEL:
        return useGetFunnelQuery

      case HomeSelectWalletTypes.RESULT:
        return useGetResultQuery

      case HomeSelectWalletTypes.EXCELLENCE_PROGRAM:
        return useGetExcellenceProgramQuery

      default:
        return null
    }
  }

  const hook = useMemo(() => switchHook(walletSelected), [walletSelected])

  //@ts-ignore
  const data = hook({
    business_code: params?.segment.code,
    microrregion_name: params?.micro_region,
    subsegment_code: params?.sub_segment.code
  } as GetWalletResumeParams)

  useEffect(() => {
    data.refetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.micro_region, params?.segment.code, params?.sub_segment.code])

  const handleGoToFullChartScreen = useCallback(
    (type: HomeChartsTypes) => {
      if (isMobile) {
        changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() =>
          navigation.navigate('HomeChart', {
            type,
            tabNumber: ''
          })
        )
      } else {
        navigation.navigate('HomeChart', {
          type,
          tabNumber: ''
        })
      }
    },
    [isMobile, navigation]
  )

  const walletResumeData = useMemo(() => {
    const resume = []

    // @ts-ignore
    const resumeData: SwitchHookReturnType = data?.data?.data.data

    let responseData

    if (resumeData)
      switch (walletSelected) {
        case HomeSelectWalletTypes.RESULT:
          responseData = resumeData as ResultDataSerialized

          resume.push(
            {
              subtitle: 'Prod. Acumulada (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.production_accumulated.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: '',
                  value: ''
                },
                {
                  title: '',
                  value: ''
                }
              ],
              updatedAt: responseData?.screen_data.production_accumulated.processed_at,
              riseUp: responseData?.screen_data.production_accumulated.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.ACCUMULATED_PRODUCTION),
              visible: true
            },
            {
              title: currencyFormatChart(
                String(responseData?.screen_data.production_projected.total_value ?? 0),
                true,
                false,
                2
              ),
              subtitle: 'Prod.Projetada (R$)',
              description: [
                {
                  title: 'Baseline',
                  value: currencyFormatChart(
                    String(
                      responseData?.screen_data.production_projected.total_baseline_value ?? 0
                    ),
                    true
                  )
                },
                {
                  title: 'Gatilho',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.production_projected.total_trigger_value ?? 0),
                    true
                  )
                }
              ],
              updatedAt: responseData?.screen_data.production_projected.processed_at,
              riseUp: responseData?.screen_data.production_projected.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.PROJECTED_PRODUCTION),
              visible: true
            },
            {
              title: currencyFormatChart(
                String(responseData?.screen_data.insurances.total_value),
                true,
                false,
                2
              ),
              subtitle: 'Receita seguros (R$)',
              description: [
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.insurances.total_expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                }
              ],
              updatedAt: responseData?.screen_data.insurances.processed_at,
              riseUp: responseData?.screen_data.insurances.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.INSURANCE_PRESCRIPTION),
              visible: !isPesados
            },
            {
              title: currencyFormatChart(
                String(responseData?.screen_data.mbb.total_value),
                true,
                false,
                2
              ),
              subtitle: 'Receita MBB (R$)',
              description: [
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.mbb.total_expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                }
              ],
              updatedAt: responseData?.screen_data.mbb.processed_at,
              riseUp: responseData?.screen_data.mbb.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.MBB_REVENUE),
              visible: !isPesados
            },
            {
              title: currencyFormatChart(
                String(responseData?.screen_data.frauds.total_value),
                true,
                false,
                2
              ),
              subtitle: 'Valor Fraudes (R$)',
              description: [
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.frauds.total_expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                }
              ],
              updatedAt: responseData?.screen_data.frauds.processed_at,
              riseUp: responseData?.screen_data.frauds.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.VALUE_FRAUDS),
              visible: true
            },
            {
              title: currencyFormatChart(
                String(responseData?.screen_data.over90.total_value),
                true,
                false,
                2
              ),
              subtitle: 'Var.Over90 (R$)',
              description: [
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over90.total_expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                }
              ],
              updatedAt: responseData?.screen_data.over90.processed_at,
              riseUp: responseData?.screen_data.over90.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.VAR_OVER90),
              visible: false
            }
          )

          break

        case HomeSelectWalletTypes.FUNNEL:
          responseData = resumeData as FunnelDataSerialized

          resume.push(
            {
              subtitle: 'Simulações (Qtd)',
              is_short_format: true,
              title: responseData?.screen_data.simulations_quantity.total_value ?? 0,
              description: [],
              updatedAt: responseData?.screen_data.simulations_quantity.processed_at,
              riseUp: responseData?.screen_data.simulations_quantity.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.AMOUNT_SIMULATIONS),
              visible: true
            },
            {
              subtitle: 'Contratos (Qtd)',
              is_short_format: true,
              title: responseData?.screen_data.contracts_quantity.total_value ?? 0,
              description: [],
              updatedAt: responseData?.screen_data.contracts_quantity.processed_at,
              riseUp: responseData?.screen_data.contracts_quantity.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.AMOUNT_CONTRACTS),
              visible: true
            },
            {
              subtitle: 'APNEF(Qtd)',
              is_short_format: true,
              title: responseData?.screen_data.apnef_quantity.total_value ?? 0,
              description: [],
              updatedAt: responseData?.screen_data.apnef_quantity.processed_at,
              riseUp: responseData?.screen_data.apnef_quantity.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.AMOUNT_APNEF),
              visible: true
            },
            {
              subtitle: 'Var APNEF (R$)',
              is_short_format: true,
              title: currencyFormatChart(
                String(responseData?.screen_data.apnef_value.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [],
              updatedAt: responseData?.screen_data.apnef_value.processed_at,
              riseUp: responseData?.screen_data.apnef_value.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.VALUE_APNEF),
              visible: true
            }
          )

          break

        case HomeSelectWalletTypes.CONCENTRATION:
          responseData = resumeData as ConcentrationDataSerialized

          resume.push(
            {
              subtitle: 'Lojas (Qtd)',
              title: currencyFormatChart(
                String(responseData?.screen_data.total_stores.total_value ?? 0),
                true,
                false,
                0
              ),
              description: [
                {
                  title: '',
                  value: ''
                },
                {
                  title: '',
                  value: ''
                },
                {
                  title: '',
                  value: ''
                }
              ],
              updatedAt: responseData?.screen_data.total_stores.processed_at,
              riseUp: responseData?.screen_data.total_stores.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.AMOUNT_STORES),
              visible: true
            },
            {
              subtitle: 'Ativas (Qtd)',
              title: currencyFormatChart(
                String(responseData?.screen_data.active_stores.total_value ?? 0),
                true,
                false,
                0
              ),
              description: [
                {
                  title: 'Simulam',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.active_stores.simulate_value ?? 0),
                    true,
                    false,
                    0
                  )
                },
                {
                  title: 'Produzem',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.active_stores.produce_value ?? 0),
                    true,
                    false,
                    0
                  )
                },
                {
                  title: 'Recorrentes',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.active_stores.recurrent_value ?? 0),
                    true,
                    false,
                    0
                  )
                }
              ],
              updatedAt: responseData?.screen_data.active_stores.processed_at,
              riseUp: responseData?.screen_data.active_stores.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.AMOUNT_ACTIVE_STORES),
              visible: true
            },
            {
              subtitle: 'Inativas (Qtd)',
              is_short_format: true,
              title: currencyFormatChart(
                String(responseData?.screen_data.inactive_stores.total_value ?? 0),
                true,
                false,
                0
              ),
              description: [],
              updatedAt: responseData?.screen_data.inactive_stores.processed_at,
              riseUp: responseData?.screen_data.inactive_stores.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.AMOUNT_INACTIVES),
              visible: true
            },
            {
              subtitle: '50% Produção (Qtd)',
              is_short_format: true,
              title: responseData?.screen_data.production_50.total_value ?? 0,
              description: [],
              updatedAt: responseData?.screen_data.production_50.processed_at,
              riseUp: responseData?.screen_data.production_50.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.AMOUNT_HALF_PRODUCTION),
              visible: true
            }
          )

          break

        case HomeSelectWalletTypes.COMMISSION:
          responseData = resumeData as CommissionDataSerialized

          resume.push(
            {
              subtitle: 'Programa Excelência',
              title: responseData?.screen_data.excellence_program.classification
                ? `${responseData?.screen_data.excellence_program.classification
                    ?.charAt(0)
                    ?.toUpperCase()}${responseData?.screen_data.excellence_program.classification
                    ?.substring(1)
                    ?.toLowerCase()}`
                : '',
              description: responseData?.screen_data.excellence_program.stores.length
                ? responseData?.screen_data.excellence_program.stores.map((item) => ({
                    title: item.store_name,
                    value: item.classification
                  }))
                : [],
              updatedAt: responseData?.screen_data.excellence_program.processed_at,
              riseUp: responseData?.screen_data.excellence_program.rise_up,
              visible: false
            },
            {
              subtitle: 'Com. Produção (R$)',
              is_short_format: true,
              title: currencyFormatChart(
                String(responseData?.screen_data.commission_production.total_value ?? 0),
                true,
                false,
                2
              ),
              description: isGoodsAndServices
                ? new Array(responseData?.screen_data.excellence_program.stores.length).fill({})
                : [{}, {}, {}, {}],
              updatedAt: responseData?.screen_data.commission_production.processed_at,
              riseUp: responseData?.screen_data.commission_production.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.PRODUCTION),
              visible: true
            },
            {
              subtitle: 'Com. Campanhas (R$)',
              is_short_format: true,
              title: currencyFormatChart(
                String(responseData?.screen_data.commission_campaign.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [],
              updatedAt: responseData?.screen_data.commission_campaign.processed_at,
              riseUp: responseData?.screen_data.commission_campaign.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.CAMPAIGNS),
              visible: isGoodsAndServices
            },
            {
              subtitle: 'Com. Resultado (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.commission_results.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: 'Seguros',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.commission_results.insurance_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: 'Fraude',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.commission_results.fraud_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: 'Over90',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.commission_results.over90_value),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: isGoodsAndServices ? 'MBB' : '',
                  value: isGoodsAndServices
                    ? currencyFormatChart(
                        String(responseData?.screen_data.commission_results.mbb_value ?? 0),
                        true,
                        false,
                        2
                      )
                    : ''
                }
              ],
              updatedAt: responseData?.screen_data.commission_results.processed_at,
              riseUp: responseData?.screen_data.commission_results.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.RESULT),
              visible: true
            },
            {
              subtitle: 'Com. A receber (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.commission_to_receive.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: 'Saldo remanes.',
                  value: currencyFormatChart(
                    String(
                      responseData?.screen_data.commission_to_receive.remaining_balance_value ?? 0
                    ),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: 'Dif.Apuração',
                  value: currencyFormatChart(
                    String(
                      responseData?.screen_data.commission_to_receive.diff_calculation_value ?? 0
                    ),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: 'Adiantamento',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.commission_to_receive.advance_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: '',
                  value: ''
                }
              ],
              updatedAt: responseData?.screen_data.commission_to_receive.processed_at,
              riseUp: responseData?.screen_data.commission_to_receive.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.RECEIVABLE),
              visible: true
            }
          )

          break

        case HomeSelectWalletTypes.CHARGE:
          responseData = resumeData as ChargeDataSerialized

          resume.push(
            {
              subtitle: 'FPD10 (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.fpd10.total_value ?? 0),
                true,
                undefined,
                2
              ),
              description: [
                {
                  title: '%',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.fpd10.percent ?? 0),
                    true,
                    undefined,
                    2
                  )
                },
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.fpd10.expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: '% Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.fpd10.expected_percent ?? 0),
                    true,
                    false,
                    2
                  )
                }
              ],
              updatedAt: responseData?.screen_data.fpd10.processed_at,
              riseUp: responseData?.screen_data.fpd10.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.FPD10),
              visible: true
            },
            {
              subtitle: 'OVER30M3 (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.over30m3.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: '%',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over30m3.percent ?? 0),
                    true
                  )
                },
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over30m3.expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: '% Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over30m3.expected_percent ?? 0),
                    true
                  )
                }
              ],
              updatedAt: responseData?.screen_data.over30m3.processed_at,
              riseUp: responseData?.screen_data.over30m3.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.OVER30M3),
              visible: true
            },
            {
              subtitle: 'Saldo Safra (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.saldo_safra.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.saldo_safra.expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: '',
                  value: ''
                },
                {
                  title: '',
                  value: ''
                }
              ],
              updatedAt: responseData?.screen_data.saldo_safra.processed_at,
              riseUp: responseData?.screen_data.saldo_safra.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.SAFRA_BALANCE),
              visible: true
            },
            {
              subtitle: 'OVER30 (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.over30.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: '%',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over30.percent ?? 0),
                    true
                  )
                },
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over30.expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: '% Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over30.expected_percent ?? 0),
                    true
                  )
                }
              ],
              updatedAt: responseData?.screen_data.over30.processed_at,
              riseUp: responseData?.screen_data.over30.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.OVER30),
              visible: true
            },
            {
              subtitle: 'OVER60 (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.over60.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: '%',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over60.percent ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over60.expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: '% Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over60.expected_percent ?? 0),
                    true,
                    false,
                    2
                  )
                }
              ],
              updatedAt: responseData?.screen_data.over60.processed_at,
              riseUp: responseData?.screen_data.over60.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.OVER60),
              visible: true
            },
            {
              subtitle: 'OVER90 (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.over90.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: '%',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over90.percent ?? 0),
                    true
                  )
                },
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over90.expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                },
                {
                  title: '% Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.over90.expected_percent ?? 0),
                    true
                  )
                }
              ],
              updatedAt: responseData?.screen_data.over90.processed_at,
              riseUp: responseData?.screen_data.over90.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.OVER90),
              visible: true
            },
            {
              subtitle: 'Saldo Carteira (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.wallet_balance.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: 'Esperado',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.wallet_balance?.expected_value ?? 0),
                    true,
                    false,
                    2
                  )
                }
              ],
              updatedAt: responseData?.screen_data.wallet_balance.processed_at,
              riseUp: responseData?.screen_data.wallet_balance.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.WALLET_BALANCE),
              visible: true
            },
            {
              subtitle: 'Fraudes (R$)',
              title: currencyFormatChart(
                String(responseData?.screen_data.fraud.total_value ?? 0),
                true,
                false,
                2
              ),
              description: [
                {
                  title: 'Qde.',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.fraud.quantity ?? 0),
                    true
                  )
                }
              ],
              updatedAt: responseData?.screen_data.fraud.processed_at,
              riseUp: responseData?.screen_data.fraud.rise_up,
              handleGoTo: () => handleGoToFullChartScreen(HomeChartsTypes.FRAUDS),
              visible: true
            },
            {
              subtitle: 'Contr. Inadimp. (Qtd)',
              title: currencyFormatChart(
                String(responseData?.screen_data.contract_delinquency_qty.total ?? 0),
                true
              ),
              description: [
                {
                  title: 'Over30',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.contract_delinquency_qty.over30 ?? 0),
                    true,
                    false,
                    0
                  )
                },
                {
                  title: 'Over60',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.contract_delinquency_qty.over60 ?? 0),
                    true,
                    false,
                    0
                  )
                },
                {
                  title: 'Over90',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.contract_delinquency_qty.over90 ?? 0),
                    true,
                    false,
                    0
                  )
                },
                {
                  title: 'FPD10',
                  value: currencyFormatChart(
                    String(responseData?.screen_data.contract_delinquency_qty.fpd10 ?? 0),
                    true,
                    false,
                    0
                  )
                }
              ],
              updatedAt: responseData?.screen_data.contract_delinquency_qty.processed_at,
              riseUp: responseData?.screen_data.contract_delinquency_qty.rise_up,
              handleGoTo: () =>
                handleGoToFullChartScreen(HomeChartsTypes.CONTRACTS_DELINQUENCY_QTY),
              visible: true
            }
          )

          break

        case HomeSelectWalletTypes.EXCELLENCE_PROGRAM:
          responseData = resumeData as ExcellenceProgramData

          resume.push({
            title: 'Sua classificação atual:',
            subtitle: '',
            stars: responseData?.current_rating?.stars_excellence_program ?? 0,
            description: [
              {
                title: 'Pontuação:',
                value: responseData?.current_rating?.points_excellence_program ?? 0
              },
              {
                title: 'Comissão Produção:',
                value: responseData?.current_rating?.production_commission_percent ?? 0
              },
              {
                title: 'Comissão Resultados:',
                value: responseData?.current_rating?.result_comission_percent ?? 0
              }
            ],
            isClassificationCard: true,
            visible: true
          })

          responseData?.excelence_kpi?.forEach((kpi) => {
            resume.push({
              title: kpi?.title,
              subtitle: '',
              description:
                kpi?.items?.map((item) => ({ title: item?.title, value: item?.value })) ?? [],
              tips: kpi?.challenges?.map((challenge, i) => ({
                title:
                  typeof challenge?.slug === 'number' && !isNaN(challenge?.slug)
                    ? currencyFormatChart(String(challenge?.slug ?? 0), true, false, 0)
                    : challenge?.slug,
                color: i === 0 ? 'primary.500' : 'primary.800'
              })),
              progressBar: {
                lines: kpi?.challenges?.map((challenge, i) => ({
                  position: Math.min(Math.max(challenge?.value, 0), 98),
                  subtitle:
                    typeof challenge?.title === 'number' && !isNaN(challenge?.title)
                      ? currencyFormatChart(String(challenge?.title ?? 0), true, false, 0)
                      : challenge?.title,
                  textColor: i === 0 ? 'primary.500' : 'primary.800'
                })),
                currentProgress: kpi.actual_challenge_value,
                bgColor: getProgressBarColor(
                  kpi?.actual_challenge_value,
                  kpi?.challenges[0]?.value,
                  kpi?.challenges[1]?.value
                )
              },
              visible: true
            })
          })

          break

        default:
          break
      }

    return resume
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, navigation, walletSelected, isGoodsAndServices, isPesados])

  const {
    isLoading: isLoadingProfile,
    data: getQueryProfileData,
    refetch: refetchProfile
  } = useGetProfileQuery()

  const profile = useMemo(() => getQueryProfileData?.data.data, [getQueryProfileData?.data.data])

  const userName =
    profile?.name && profile?.name.split(' ').length > 1
      ? profile?.name.split(' ')[0]
      : profile?.name

  const imageProfile = profile?.profile_image_url

  const today = new Date()

  const todayDate = formatDateStringToMask(today.toISOString(), 'dd/MM/yyyy')

  const nameOfDay = format(today, 'EEEEEE', {
    locale: ptBR
  })

  const dayOfWeek = useMemo(
    () => daysOfWeek[nameOfDay as string as keyof typeof daysOfWeek],
    [nameOfDay]
  )

  const {
    data: rvIndicatorResume,
    isLoading: isLoadingIndicationResume,
    refetch: refetchIndicatorResume
  } = useGetIndicatorResumeQuery()

  const rvIndicatorData = rvIndicatorResume?.data.data

  const {
    data: getAllNotifications,
    isLoading: newsLoading,
    refetch: refetchAllNotifications
  } = useGetAllNotificationsInfinityQuery({
    notification_type: NOTIFICATION_TYPES.COMMUNICATION
  })

  const newsData = useMemo(
    () =>
      getAllNotifications?.pages.reduce<TNotification[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data] as TNotification[]
      }, []),
    [getAllNotifications?.pages]
  )

  const handleGoToProductionChart = useCallback(() => {
    LogEvent(`resumo_carteira_producao`, {
      item_id: ''
    })

    if (isMobile) {
      changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() =>
        navigation.navigate('ProductionChart')
      )
    } else {
      navigation.navigate('ProductionChart')
    }
  }, [navigation, isMobile])

  const handleGoToProfile = useCallback(
    () => navigation.navigate('Tab', { screen: 'Profile' }),
    [navigation]
  )

  const handleGoToOpportunitiesList = useCallback(
    () => navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 2 } }),
    [navigation]
  )

  const {
    data: highlightedData,
    isLoading: highlightedIsLoading,
    isFetchingNextPage: highlightedIsFetchingNextPage,
    hasNextPage: highlightedHasNextPage,
    fetchNextPage: highlightedFetchNextPage,
    refetch: refetchKnowledgeBaseIsFeatured
  } = useGetKnowledgeBasesInfiniteQuery({
    is_featured: 1
  })

  const linkTo = useLinkTo()

  const highlightedList = useMemo(() => mergeDataInfiniteQuery(highlightedData), [highlightedData])

  const onEndReachedHighlightedList = useCallback(() => {
    if (!highlightedIsFetchingNextPage && highlightedHasNextPage) {
      highlightedFetchNextPage()
    }
  }, [highlightedFetchNextPage, highlightedHasNextPage, highlightedIsFetchingNextPage])

  const handleGoToContentScreen = useCallback(
    (content: ContentSerialized) => linkTo(`/conteudos/${content.id}/detalhes`),
    [linkTo]
  )

  const [progress, setProgress] = useState(
    setProgressValue({
      total_baseline_value: 0,
      total_current_value: 0,
      total_projection_value: 0,
      total_trigger_value: 0,
      total_trigger_two_value: 0,
      is_awaiting_receive_full_rv_data: false,
      total_baseline_point: 0,
      total_current_point: 0,
      total_projection_point: 0,
      total_trigger_point: 0
    })
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      !!rvIndicatorData && setProgress(setProgressValue(rvIndicatorData))
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [rvIndicatorData])

  const onRefresh = async () => {
    setRefreshing(true)

    setProgress(
      setProgressValue({
        rv_potentials: [],
        total_baseline_value: 0,
        total_current_value: 0,
        total_projection_value: 0,
        total_trigger_value: 0,
        total_trigger_two_value: 0,
        is_awaiting_receive_full_rv_data: false,
        total_baseline_point: 0,
        total_current_point: 0,
        total_projection_point: 0,
        total_trigger_point: 0
      })
    )

    await refetchAllNotifications()

    await refetchIndicatorResume().then((res) => {
      const newRVIndicatorData = res.data?.data.data

      const timeout = setTimeout(() => {
        !!newRVIndicatorData && setProgress(setProgressValue(newRVIndicatorData))
      }, 600)

      return () => {
        clearTimeout(timeout)
      }
    })

    await refetchKnowledgeBaseIsFeatured()

    await refetchOpportunities()

    await refetchProfile()

    await data.refetch()

    setRefreshing(false)
  }

  const handleGoToFilters = () => {
    navigation.navigate('HomeFilter', params)
  }

  const handleGoToOpportunitiesListBySegment: THandleGoToOpportunitiesListBySegment = (
    title,
    simulatedPoints,
    total,
    businessBlockId
  ) => {
    LogEvent(
      `ver_todas_oportUnidades_${title
        .normalize('NFD')
        .replace(/\p{Mn}/gu, '')
        .replace(' ', '_')}`,
      {
        item_id: ''
      }
    )

    linkTo(`/oportunidade/${title}/${simulatedPoints}/${total}/${businessBlockId}`)
  }

  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const widthFilter =
    SCREEN_WIDTH > 400 ? Math.floor(SCREEN_WIDTH / 1.75) : Math.floor(SCREEN_WIDTH / 1.85)

  const walletSheetRef = useRef<BottomSheetModal>(null)

  const openWalletSheet = useCallback(() => {
    walletSheetRef.current?.present()
  }, [])

  const closeWalletSheet = useCallback(() => {
    walletSheetRef.current?.close()

    walletSheetRef.current?.dismiss()
  }, [])

  useEffect(() => {
    const refetch = route.params?.refetch

    if (refetch) {
      refetchIndicatorResume()

      refetchOpportunities()
    }
  }, [queryClient, refetchIndicatorResume, refetchOpportunities, route])

  return {
    isExcellenceProgramWallet,
    isMobile,
    widthFilter,
    newsData,
    walletSelected,
    walletSheetRef,
    openWalletSheet,
    closeWalletSheet,
    onChangeSelectWallet,
    onRefresh,
    handleGoToFilters,
    filtersSize,
    refreshing,
    isLoading: newsLoading,
    highlightedList,
    highlightedIsLoading,
    onEndReachedHighlightedList,
    highlightedIsFetchingNextPage,
    handleGoToContentScreen,
    handleGoToProductionChart,
    handleGoToProfile,
    handleGoToFullChartScreen,
    progress,
    userName,
    imageProfile,
    initialLettersName: profile?.name,
    todayDate,
    dayOfWeek,
    opportunitiesList,
    isLoadingOpportunities,
    walletSummaryList: walletResumeData,
    isLoadingIndicationResume,
    handleGoToOpportunitiesList,
    isLoadingProfile,
    isVehicles,
    isLoadingWalletSelected: isLoadingWalletSelected || data?.isFetching,
    consultantRV,
    regionalRV,
    isLeves,
    isPesados,
    isGoodsAndServices,
    handleGoToOpportunitiesListBySegment,
    rvSummary: rvSummaryData?.data.data,
    isLoadingRvSummary
  }
}
